import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={false} isDark={true}>
        <div className="odd">
        <SEO title="Ablaufotimpierung durch Software Entwicklung"/>

            <div className="container-fluid hero-full hero-big has-bg-img">

                <picture>
                    <source srcSet={require("../../images/services/apps/hero.jpg")} type="image/jpeg"/>
                    <source srcSet={require("../../images/services/apps/hero.jpg.webp")} type="image/webp"/>
                    <img src={require("../../images/services/apps/hero.jpg")}/>
                </picture>

                <div className="text-center">
                    <h1 className="text-white">App Entwicklung</h1>
                    <p className="hidden-sm  text-white mt-2"> Bitte nach unten scrollen.</p>
                </div>
            </div>

            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/create.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/create.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/create.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Erstellen Sie Apps</h1>
                        <p className="text-gray">Bauen Sie Ihre Zukunft auf. Egal, ob mit in-House Apps oder für die Geräte Ihrer Kund*innen.</p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/prototype.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/prototype.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/prototype.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Prototyp</h1>
                        <p className="text-gray">
        Erleben Sie eine reale Vorschau Ihrer zukünftigen App bereits wenige Tage nach dem ersten Gespräch.
        </p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/accessibility.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/accessibility.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/accessibility.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Apps zugänglich machen</h1>
                        <p className="text-gray">Wir legen den Fokus auf intuitive Oberflächen und befolgen für unsere iOS Apps präzise die Apple Human Interface Guidelines. </p>
                        <p className="text-gray text-small">* Details unter developer.apple.com/design</p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/connected.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/connected.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/connected.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Verbunden</h1>
                        <p className="text-gray">
        Führen Sie Aktivitäten auf einem beliebigen Gerät aus und sehen Sie, wie die Daten auf allen anderen Geräten und auf Ihrer Webseite aktualisiert werden.
        </p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/android.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/android.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/android.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Android</h1>
                        <p className="text-gray">Selbstverständlich bieten wir auch Android App Programmierung an.
        Dabei zeigen Ihre iOS und Ihre Android App dieselben Daten, orientieren sich allerdings an den User Interface Richtlinien der jeweiligen Plattform.</p>
                        <p className="text-gray text-small">* Details unter developer.android.com/design</p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/smarthome.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/smarthome.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/smarthome.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Smart Home</h1>
                        <p className="text-gray">
        Zur Vertiefung der Benutzerinteraktion können Sie Ihr Angebot über  
        Dienste wie zum Beispiel Google Assistant oder Facebook Messenger zur Verfügung stellen.
        Smart Home gibt es für Telefone, Autos, Kopfhörer, Uhren und viele weitere Geräte.
        </p>
                        <picture>
                            <source srcSet={require("../../images/services/apps/dialogflow.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/dialogflow.jpg.webp")} type="image/webp"/>
                            <img
                                className="mt-2"
                                style={{width:"100%"}}
                                src={require("../../images/services/apps/dialogflow.jpg")}/>
                        </picture>
                        
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/apps/offline.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/apps/offline.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/apps/offline.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Offline</h1>
                        <p className="text-gray">
        Aufgrund ihrer nativen Infrastruktur können unsere Smartphone Apps offline arbeiten.
        Im Gegensatz zu Web-Apps kann die App auf zuvor geladene Daten zurückgreifen.</p>
                </div>
            </div>


            <div className="container-fluid hero-full has-bg-img">

                <picture>
                    <source srcSet={require("../../images/services/apps/fruits.jpg")} type="image/jpeg"/>
                    <source srcSet={require("../../images/services/apps/fruits.jpg.webp")} type="image/webp"/>
                    <img src={require("../../images/services/apps/fruits.jpg")}/>
                </picture>

                <div style={{position:"absolute",right:"3vw",bottom:"50px"}}>
                    <h1 style={{textShadow:"none"}}>... und das Beste</h1>
                </div>
            </div>




        <div className="container text-center" style={{paddingTop:"120px",paddingBottom:"30px"}}>

            <div style={{width:"800px",margin:"auto"}}>
                <h1>Eine für Alle</h1>
                <p className="text-gray">Apps, die sich automatisch an alle iOS Geräte anpassen und gemeinsam kommunizieren.</p>
                <p className="text-gray text-small mt-2" style={{width:"400px",margin:"auto"}}>
                    * All-Screen-Unterstützung auch für Android verfügbar</p>
            </div>

            <picture>
                <source srcSet={require("../../images/services/apps/all-screen.jpg")} type="image/jpeg"/>
                <source srcSet={require("../../images/services/apps/all-screen.jpg.webp")} type="image/webp"/>
                <img src={require("../../images/services/apps/all-screen.jpg")} style={{maxWidth:"900px",margin:"100px auto"}}/>
            </picture>

        </div>
        </div>

            <div className="container-fluid hero" id="coffee">
                <div className="container">

                        <picture>
                            <source srcSet={require("../../images/landing/coffee_1500.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../../images/landing/coffee_1500.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../../images/landing/coffee_1500.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1 className="text-white">Auf n Kaffee?</h1>
                        <p className="text-white">Gerne erzählen wir Ihnen mehr!  </p>
                        <Link to="/mehr-erfahren" className="text-white btn btn-lime-outline">Jetzt anfragen ›</Link>
                    </div>
                </div>
            </div>



     
      </Layout >
    )
  }
}

